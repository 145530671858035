export default { install(vue) {
   vue.prototype.$alert = function(title, text, type) {
      if(typeof title === 'object') {
         this.$store.commit('addAlert', title);
         if(title.timer) setTimeout(() => {
            this.$store.commit('delAlert', this.$store.state.alert.indexOf(title));
            if(typeof title.cb === 'function') title.cb();
         }, title.timer);
      } else this.$store.commit('addAlert', { title, text, type });
   }
}}
